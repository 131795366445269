@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Langar&display=swap');

.absol {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 5.5px;
}

.absol-line {
  position: absolute;
  width: 200px;
  height: 100px; /* as the half of the width */
  background-color: transparent;
  border-top-left-radius: 110px; /* 100px of height + 10px of border */
  border-top-right-radius: 110px; /* 100px of height + 10px of border */
  border: 0.5px solid #5d2381;
  border-bottom: 0;
  top: 73%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rel {
  position: relative;
  padding: 5% 0 0;
}

#gauge-chart3 {
  width: 80% !important;
  margin: 0 auto;
}

#gauge-chart4 .needle {
  display: none;
}

.needle-img{
  position: absolute;
  width: 15%!important;
  height: auto!important;
  /* z-index: -1; */
  top: 85.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shadow-svg {
  position: absolute;
  /* left: 7%; */
  /* bottom: -40%; */
  z-index: -1;
  top: 105.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shadow-svg span{
  position: absolute;
  left: 29%;
  top: 43%;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  color: #106E25;
}


.ticker{
  background-color: #F3F8F4;
  padding: 1rem;
  color: #106E25;
  stroke-width: 1px solid #106e2484;
  font-size: 25px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  margin-top: 1rem;
}

#gauge-chart5 .needle::before{
  content: url('https://tknismtest.s3.amazonaws.com/global_dev/Vector%20%285%29-1692707590767.png');
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.timeline .active{
  background-color: yellow;
}

.player-wrapper {
  width: auto; 
  height: auto; 
}
.react-player {
  padding-top: 56.25%;
  position: relative; 
}

.react-player > div {
  position: absolute;
}

.react-player__preview svg:hover{
  transform: scale(1.1);
  transition: 0.3s ease-out;
}

.loader {
  border: 8px solid #CCCCCC; /* Light grey */
  border-top: 8px solid #106E25; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal h4{
  padding-left: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

.modal .error{
  color: red;
  padding-left: 12px;
  font-size: 12px;
}

.modal .pl-0{
  padding-left: 0px;
}

.modal .btn-container{
  display: flex;
  justify-content: space-around;
}

.modal-body{
  padding: 1rem 2rem;
}

.modal .primary-btn{
  border : none ;
  background-color: #106E25;
  color: white;
  font-size: 16px;
  font-family: 'Langar', cursive;
  width: 200px;
  height: 60px;
  margin: 12px 0;
}

.modal .disabled{
  border : none ;
  background-color: #666666;
  color: white;
  font-size: 16px;
  font-family: 'Langar', cursive;
  width: 200px;
  height: 60px;
  margin: 12px 0;
}

.modal .disabled:hover{
  cursor: no-drop;
}

.modal .secondary-btn{
  border : none ;
  background-color: #CFE2D3;
  color: #106E25;
  font-size: 16px;
  font-family: 'Langar', cursive;
  width: 200px;
  height: 60px;
  margin: 12px 0;
}

.modal input[type="text"], .modal input[type="email"]{
  width: 100%;
  height: 52px;
  border: 0;
  border-bottom: 1px solid #CCCCCC;
  padding: 0 0.5rem;
}

.modal input[type="radio"]{
  /* width: 100%; */
  height: 20px;
  border: 0;
  border-bottom: 1px solid #CCCCCC;
  padding: 0 0.5rem;
  margin-right: 0.75rem;
}

.modal .f-right{
  float: right;
}

.modal .button-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal hr{
  margin-top: 1rem;
}

.modal p{
  font-size: 13px;
  color: #666666;
  font-family: 'Inter', sans-serif;
}
/*
.modal input:hover{
  border: 1px solid #106E25;
} */

.modal input:focus-visible{
  outline: 0;
}

.modal .row label{
  margin-top: 0.25rem;
  font-size: 14px;
  color: #666666;
}

.FormGroup{
  padding-left: 12px;
  margin: 12px 0
}

.FormGroup label{
  margin-bottom: 0.75rem;
  font-size: 14px;
  color: #666666;
}

.landing nav{
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing nav .nav-link{
  color: #106E25;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
}

.landing nav .nav-link:hover{
  cursor: pointer;
}

.nav-link:nth-child(2) {
  margin-left: 1rem;
}

header{
  background-image: url('https://tknismtest.s3.amazonaws.com/global_dev/Clip%20path%20group-1691558874717.png');
  /* height: 378px;  */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #F3F8F4;
}

header .note{
  font-size: 9px;
  font-family: 'Inter', sans-serif;
  width: 38%;
  margin: auto;
  text-align: center;
  color: #666666;
}

header .header-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 6rem 0px;
}

header h1{
  color: #106E25;
  font-size: 70px;
  font-family: 'Langar', cursive;
  font-weight: 500;
  width: 50%;
  text-align: center;
  margin: 0;
}

header h4{
  font-size: 33px;
  font-family: 'Langar', cursive;
  font-weight: 500;
  margin: 0;
  color: #333333;
}

header button{
  border : none ;
  background-color: #106E25;
  color: white;
  font-size: 26px;
  font-family: 'Langar', cursive;
  width: 300px;
  height: 60px;
  margin: 12px 0;
}

header a{
  font-size: 20px;
  font-family: 'Langar', cursive;
}

.content-center{
  text-align: center;
  padding: 8rem 20rem;
}

.content-center h4{
  font-size: 33px;
  font-family: 'Langar', cursive;
  font-weight: 500;
  margin: 0;
  color: #106E25;
}

.content-center p{
  color: #666666;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  margin: 1rem 0 2rem 0;
}

.content-center .note{
  font-size: 9px;
  font-family: 'Inter', sans-serif;
  width: 70%;
  margin: auto;
}

.content-left .note{
  font-size: 9px;
  font-family: 'Inter', sans-serif;
}

.content-center .button-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-center .button-container form{
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-center .button-container form input[type="image"]{
  width: 230px;
  height: 60px;
}

.content-center .button-container button:nth-child(2) {
  margin-left: 1rem;
}

.content-center .primary-btn{
  border : none ;
  background-color: #106E25;
  color: white;
  font-size: 16px;
  font-family: 'Langar', cursive;
  width: 230px;
  height: 60px;
  margin: 12px 0;
}

.primary-btn:hover{
  background-color: #519661;
}

.content-center .secondary-btn{
  border : none ;
  background-color: #CFE2D3;
  color: #106E25;
  font-size: 16px;
  font-family: 'Langar', cursive;
  width: 230px;
  height: 60px;
  margin: 12px 0;
}

.secondary-btn:hover{
  background-color: #106E25;
  color: white;
}

.timeline h4{
  font-size: 33px;
  font-family: 'Langar', cursive;
  color: #106E25;
}

.timeline svg{
  margin: 3rem 0;
}

.card-container{
  text-align: center;
  padding: 0rem 8rem 8rem 8rem;
}

.card-container .row{
  justify-content: space-around;
}

.card-container .card-div{
  padding: 2rem 1rem;
  width: 370px;
  height: 252px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: inherit;
  border: 1px solid #CCCCCC;

}

.card-container .card-div h5{
  font-size: 20px;
  font-family: 'Langar', cursive;
  color: #106E25;
}

.card-container .card-div p{
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}

.card-container .full-card-div{
  padding: 2rem 1rem;
  width: 770px;
  height: 252px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: inherit;
}

.card-container .full-card-div h4{
  font-size: 33px;
  font-family: 'Langar', cursive;
  color: #106E25;
}


.card-container .full-card-div p{
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}

.content-left{
  background-color: #F3F8F4;
  padding: 8rem;
  text-align: left;
}

.no-bg-btn{
  background-color: transparent;
}

.content-left h4{
  font-size: 33px;
  font-family: 'Langar', cursive;
  font-weight: 500;
  margin: 0;
  color: #106E25;
}

.content-left p{
  color: #666666;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  margin: 1rem 0 2rem 0;
}

.content-left .primary-btn{
  border : none ;
  background-color: #106E25;
  color: white;
  font-size: 16px;
  font-family: 'Langar', cursive;
  width: 230px;
  height: 60px;
  margin: 12px 0;
}

.content-left .col:nth-child(1){
  margin: auto;
}

.content-left .col:nth-child(1) form input[type="image"]{
  width: 250px;
  height: 60px;
}

.content-left .col:nth-child(2){
  text-align: center;
}

.content-right{
  padding: 8rem;
  text-align: left;
}

.content-right h4{
  font-size: 33px;
  font-family: 'Langar', cursive;
  font-weight: 500;
  margin: 0;
  color: #106E25;
}

.content-right p{
  color: #666666;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  margin: 1rem 0 2rem 0;
}

.content-right .primary-btn{
  border : none ;
  background-color: #106E25;
  color: white;
  font-size: 16px;
  font-family: 'Langar', cursive;
  width: 230px;
  height: 60px;
  margin: 12px 0;
}

.content-right .col:nth-child(2){
  margin: auto;
}

.content-right .col:nth-child(1){
  text-align: center;
}

.content-left .react-player__preview{
  margin-top: 2rem;
  width: inherit;
  height: fit-content;
}

.stats-container{
  background-color: #F3F8F4;
  z-index: -4;
  position: relative;
  padding: 8rem;
  text-align: center;
}

.stats-container .center{
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
}

.canvas-container {
  height: 60vh;
}

.stats-container img{
  width: 499px;
  height: 484px;
}

.stats-container .col:nth-child(2){
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.stats-container .stats-info-card{
  background-color: white;
  padding: 1rem 1.5rem;
  width: 364px;
}

.stats-container .stats-info-card:nth-child(1){
  margin-bottom: 1rem;
}

.stats-container .label{
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  color: #666666;
}

.stats-container .amount{
  font-family: 'Inter', sans-serif;
  font-size: 50px;
  color: #106E25;
}

.contact-tabs-container{
  padding: 0 12rem 4rem 12rem;
}

.contact-tabs-container .title{
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #333333;
}

.contact-tabs-container .desc{
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  color: #666666;
}

.sponsors-container{
  padding: 4rem 4rem 0 4rem;
  text-align: center;
}

.sponsors-container h4{
  font-size: 33px;
  font-family: 'Langar', cursive;
  font-weight: 500;
  margin: 4rem 0 5rem 0;
  color: #106E25;
}

.sponsors-container .family{
  display: flex;
  justify-content: center;
  align-items: center;
}

.sponsors-container .family img{
  width: 57px;
  height: 66px;
  margin-right: 1rem;
}

.sponsors-container .family p{
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  color: #666666;
  font-weight: 600;
}

.sponsors-container .logos{
  margin: auto;
}

.sponsors-container .logo-1{
  width: 278px;
  height: 182px;
}

.sponsors-container .logo-2{
  width: 328px;
  height: 73px;
}

.sponsors-container .logo-3{
  width: 200px;
  height: 185px;
}

hr{
  background-color: #CFE2D3;
  height: 5px;
  border-radius: 5px;
  border-top: 0;
  margin-top: 3rem;
}

.contact-us{
  text-align: center;
  padding: 8rem 13rem;
}

.contact-us h4{
  font-size: 33px;
  font-family: 'Langar', cursive;
  font-weight: 500;
  margin: 0;
  color: #106E25;
}

.contact-us p{
  color: #666666;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  margin: 1rem auto 2rem auto;
  width: 75%;
}

.contact-us input{
  width: 100%;
  height: 52px;
  margin-bottom: 1rem;
  border-color: 1px solid #CCCCCC;
  padding: 0 1rem;
}

.contact-us input:hover{
  border: 1px solid #106E25;
}

.contact-us input:focus-visible{
  outline: 0.25px solid #106E25;
}

.contact-us textarea{
  width: 100%;
  height: 140px;
  border-color: 1px solid #CCCCCC;
  padding: 1rem;
}

.contact-us textarea:hover{
  border: 1px solid #106E25;
}

.contact-us textarea:focus-visible{
  outline: 0.25px solid #106E25;

}

footer{
  background-color: #333333;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0;
}

footer p{
  text-align: center;
  margin: 0;
}

footer div a{
  margin-right: 1rem;
}

.success-msg{
  color: #106E25;
  font-size: 20px;
}
@media only screen and (min-width: 1550px) {
  .absol-line{
    display: none;
  }
  }
@media only screen and (max-width: 1360px) {
.card-container .card-div{
  margin-bottom: 1rem;
}
}
@media only screen and (max-width: 1360px) {
.card-container .full-card-div{
  margin-bottom: 1rem;
}
.absol-line{
  display: none;
}

}


@media only screen and (max-width: 1024px) {
  header h1{
    font-size: 45px;
  }
  .content-center{
    padding: 4rem;
  }
  .card-container{
    padding: 0 4rem 4rem 4rem;
  }
  .content-left{
    padding: 4rem;
  }
  .content-left col{
    text-align: center;
  }
  /* .content-left img{
    margin-top: 2rem;
  } */
  .content-right{
    padding: 4rem;
  }
  .content-right col{
    text-align: center;
  }
  .content-right img{
    margin-bottom: 2rem;
  }
  .stats-container .col:nth-child(2){
    margin-top: 5rem;
  }
  .absol-line{
    top: 67%;
  }
  .stats-container img{
    width: 399px;
    height: 384px;
  }
  .sponsors-container .logo-1{
    width: 149px;
    height: 92px;
    object-fit: cover;
  }
  .sponsors-container .logo-2{
    width: 228px;
    height: 51px;
    object-fit: cover;
  }
  .sponsors-container .logo-3{
    width: 141px;
    height: 85px;
    object-fit: cover;
  }
  .contact-tabs-container{
    padding: 0 6rem 4rem 6rem;
  }
  .contact-us{
    padding: 4rem 6.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .sponsors-container .logo-1, .sponsors-container .logo-2{
    margin-bottom: 2rem;
  }
  .absol-line{
    top: 62%;
  }

  .stats-container{
    padding: 4rem;
  }
  .sponsors-container .family{
    justify-content: start;
  }
  .content-center .button-container{
    flex-direction: column;
  }

  .content-center .button-container button:nth-child(2){
    margin-left: 0;
  }

}
@media only screen and (max-width: 600px) {

  header h1{
    font-size: 25px;
  }
  header h4{
    font-size: 20px;
  }
  header button{
    font-size: 20px;
  }
  .content-center{
    padding: 4rem 5rem;
  }
  .content-center .note{
    width: 100%;
    font-size: 8px;
  }
  .content-left{
    padding: 4rem;
  }
  .content-left .note{
    width: 100%;
    font-size: 8px;
  }
  .content-left col{
    text-align: center;
  }
  .content-left .left-img{
    margin-top: 2rem;
    width: 300px;
    height: auto;
  }
  .content-right{
    padding: 4rem;
  }
  .content-right col{
    text-align: center;
  }
  .content-right img{
    margin-bottom: 2rem;
    width: 300px;
    height: auto;
  }
  .content-left .react-player__preview{
    margin-top: 2rem;
    width: 300px;
    height: fit-content;
  }
  .stats-container img{
    width: 299px;
    height: 284px;
  }
  .stats-container .amount{
    font-size: 30px;
  }
  .stats-container .label{
    font-size: 20px;
  }
  .sponsors-container .family{
    text-align: left;
  }
  .contact-us{
    padding: 4rem 5rem;
  }
  footer{
    flex-direction: column;
  }
  .shadow-svg svg{
    width: 350px;
  }
}
@media only screen and (max-width: 425px) {
  header h1{
    font-size: 30px;
  }
  header .note{
    width: 90%;
  }
  header h4{
    font-size: 23px;
  }
  .content-center{
    padding: 3rem;
  }
  .card-container{
    padding: 3rem;
  }
  .content-left{
    padding: 3rem;
  }
  .content-left col{
    text-align: center;
  }
  /* .content-left img{
    margin-top: 2rem;
    width: 300px;
    height: auto;
  } */
  .content-right{
    padding: 3rem;
  }
  .content-right col{
    text-align: center;
  }
  .content-right img{
    margin-bottom: 2rem;
    width: 300px;
    height: auto;
  }
  .content-left .react-player__preview{
    margin-top: 2rem;
    width: 300px;
    height: fit-content;
  }
  .stats-container img{
    width: 199px;
    height: 184px;
  }
  .contact-us{
    padding: 3rem;
  }
  .contact-tabs-container{
    padding: 3rem;
  }
  .contact-tabs-container .desc{
    font-size: 14px;
  }
}
